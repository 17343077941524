<template>
  <div class="mb-4">
    <DsfrAlert type="warning" class="mb-4" title="Cette déclaration est issue de l'historique Teleicare">
      <p>L'import de l'historique est en cours. Les informations suivantes arriveront bientôt :</p>
      <ul>
        <li>la composition</li>
        <li>les entreprises mandataires s'il y en a</li>
        <li>l'étiquettage</li>
        <li>l'attestation</li>
      </ul>
    </DsfrAlert>
  </div>
</template>
