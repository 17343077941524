<template>
  <div class="mb-4">
    <DsfrAlert
      size="sm"
      title="Historique des déclarations soumises sur Teleicare"
      description="Les produits que vous avez déjà déclarés sur Téléicare sont en cours d'importation grâce à une correspondance basée sur les SIRET et les numéros de TVA intracommunautaire.
        Certaines informations apparaissent de manière incomplète dans vos historiques (la composition, l'étiquettage, l'entreprise mandataire s'il y en a, l'attestation ne sont pas encore disponibles).
        La fonctionnalité de duplication sera effective ensuite.
        En attendant, vous pouvez accéder à l'historique complet directement sur Téléicare."
      type="warning"
    ></DsfrAlert>
  </div>
</template>
